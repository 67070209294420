<template lang="pug">
include ../../../configs/template.pug
div.container-fluid.pa-0
  div.row.text-left
    div.col-12.col-sm-12.col-md-6
      +data-info('createDate', 'getDateFormat(paketStatementById.created_at)')
    div.col-12.col-sm-12.col-md-6
      +data-info('affiliate', 'getDirectoryObject({value: "affiliate", id: paketStatementById.branch_office })[nameLang]')
    div.col-12.col-sm-12.col-md-6
      +data-info('number', 'paketStatementById.full_number')
    div(v-if="paketStatementById.rank").col-12.col-sm-12.col-md-6
      +data-info('rank', 'getDirectoryObject({ value: "ranks", id: paketStatementById.rank })?.[nameLang]')
    div(v-else).col-12.col-sm-12.col-md-6
      span {{ $t("documentApplication") }}
    div(v-if="!paketStatementById.rank").col-12.col-sm-12.col-md-6
      +data-info('dateStartEvent','getDateFormat(paketStatementById.date_start_meeting)')
    div(v-if="!paketStatementById.rank").col-12.col-sm-12.col-md-6
      +data-info('dateEndEvent','getDateFormat(paketStatementById.date_end_meeting)')
    div(v-if="paketStatementById?.rank").col-12.col-sm-12.col-md-6
      div.d-flex
        div.mr-1
          b {{ $t('position') }}:
        div.d-flex.flex-column
          div(v-for="(item, index) in paketStatementById.list_positions" :key="item")
            | {{ getDirectoryObject({ value: 'positions', id: item })?.[nameLang] }}{{paketStatementById.list_positions.length - 1 > index ? ';' : ''}}

    div.col-12.col-sm-12.col-md-6
      +data-info('payment', 'paketStatementById.is_payed ? $t("isPayed") : $t("notPayed")')
    div.col-12.col-sm-12.col-md-6
      +data-info('createdBy', 'paketStatementById.manager')
    div.col-12.col-sm-12.col-md-6
      b {{ $t('price') }}:
      span {{ paketStatementById.price }} {{ $t('uah') }}
    div(v-if="paketStatementById.payment_date").col-12.col-sm-12.col-md-6
      +data-info('paymentDate','paketStatementById.payment_date')
    div(v-if="sailorIsCadet").col-12.col-sm-12.col-md-6
      +data-info('educationWithSQC', 'paketStatementById.education_with_sqc ? $t("yes") : $t("no")')
    div(v-if="checkAccess('backOffice') && paketStatementById.rank").col-12.col-sm-12.col-md-6
      v-btn(@click="newRestatementSQC" :loading="isRestatementLoading" color="primary") {{ $t('restatementSQC') }}
  div.row.text-left
    div(v-if="paketStatementById.dependencies?.documents_and_statement.length").col-12
      label {{ $t('docs') }}:
      TableV2(
        :headers="existAndAfterDocsFields"
        :items="paketStatementById.dependencies?.documents_and_statement"
        @openPaymentReceiptModal="openPaymentReceiptModal"
        @openUploadCopyFileETIModal="openUploadCopyFileETIModal"
        @createPayment="createPayment"
        :actions="actions.documents"
        isHiddenTableCount
        isHiddenPageCount
        isHiddenTableSearch)

    div(v-if="paketStatementById.dependencies?.missing.length").w-100.mt-2
      label {{ $t('missingStatement') }}:
      TableV2(
        :headers="missingDocFields"
        :items="paketStatementById.dependencies.missing"
        @openPaymentReceiptModal="openPaymentReceiptModal"
        @openUploadCopyFileETIModal="openUploadCopyFileETIModal"
        @createPayment="createPayment"
        :actions="actions.missingStatement"
        isHiddenTableCount
        isHiddenPageCount
        isHiddenTableSearch
        isHiddenPaymentBtn)

    div(v-if="paketStatementById.dependencies?.crewing_and_service.length").w-100.mt-2
      label {{ $t('other') }}:
      TableV2(
        :headers="crewingManagerAndServiceCenterFields"
        :items="paketStatementById.dependencies.crewing_and_service"
        @openPaymentReceiptModal="openPaymentReceiptModal"
        @openUploadCopyFileETIModal="openUploadCopyFileETIModal"
        @createPayment="createPayment"
        isHiddenTableCount
        isHiddenPageCount
        isHiddenTableSearch
        isHiddenPaymentBtn)

    SailorPositionStatementReceiptUpload(
      ref="sailorPositionStatementReceiptUpload"
      :selectedDocument="selectedDocument")
    ModalWindow(ref="packageModal")
      template(#title) {{$t('addDocToPackage')}}
      template(#body)
        +select-validation('body.document_id', 'acailableDocuments', 'docNumber', '"number_document"', '["required"]')(item-value="document_id")
        +select-validation('body.item_status', 'listStatus', 'status', 'nameLang', '["required"]')(item-value="value").mt-4
        div.w-100.text-left.p-0
          +radio-group('body.with_date_start', 'termReports', 'termReport')(row)
      template(#footer)
        v-btn(color="error" outlined @click="closeModalPackageModal").mr-4 {{$t('cancel')}}
        v-btn(color="success" @click="addDocunent" :loading="isLoading") {{$t('add')}}
    ModalWindow(ref="uploadFileCertificateETIModal")
      template(#title) {{$t('uploadInternationalCertificateETI')}}
      template(#body)
        FileDropZone(
          :error-messages="$valid.validation($v.mediaFilesArray, ['required'])"
          ref="mediaContent").w-100.mt-1.pa-0
      template(#footer)
        v-btn(color="error" outlined @click="closeModal").mr-4 {{$t('cancel')}}
        v-btn(color="success" @click="uploadFileCertificate" :loading="isLoading") {{$t('add')}}
</template>

<script>
import { getDateFormat, getStatus, setLinksByContentType } from '@/mixins/main'
import { checkAccess } from '@/mixins/permissions'
import { mapGetters, mapState, mapActions } from 'vuex'
import { SUCCESS_CODE } from '@/configs/constants'
import { addDocToPackage } from '@/mixins/validationRules'
export default {
  props: {
    paketStatementById: { type: Object, default: () => ({}) }
  },
  components: {
    SailorPositionStatementReceiptUpload: () => import('./SailorPositionStatementReceiptUpload.vue'),
    ControlButtons: () => import('@/components/atoms/ControlButtons'),
    FileDropZone: () => import('@/components/atoms/DropZone/DropZone.vue')
  },
  data () {
    return {
      missingDocFields: [
        { value: 'type_document_name', text: this.$t('typeDoc') },
        { value: 'info_for_statement.document_description', text: this.$t('nameDoc') },
        { value: 'date_meeting.date_start_meeting', text: this.$t('dateStartEvent') },
        { value: 'date_meeting.date_end_meeting', text: this.$t('dateEndEvent') },
        { value: 'info_for_statement.standards_text', text: this.$t('requirements') },
        { value: 'payment_info', text: this.$t('payment') },
        { value: 'event', text: '' }
      ],
      crewingManagerAndServiceCenterFields: [
        { value: 'type_document_name', text: this.$t('typeDoc') },
        { value: 'info_for_statement.name', text: this.$t('nameUa') },
        { value: 'info_for_statement.additional', text: this.$t('nameEn') },
        { value: 'price', text: this.$t('price') }
      ],
      existAndAfterDocsFields: [
        { value: 'type_document_name', text: this.$t('typeDoc') },
        { value: 'info_for_statement.number', text: this.$t('number') },
        { value: 'info_for_statement.name_issued', text: this.$t('issued') },
        { value: 'info_for_statement.date_start', text: this.$t('dateEffective') },
        { value: 'info_for_statement.date_end', text: this.$t('dateTermination') },
        { value: 'info_for_statement.info', text: this.$t('position') },
        { value: 'payment_info', text: this.$t('payment') },
        { value: 'status', text: this.$t('status') },
        { value: 'event', text: '' }
      ],
      selectedDocument: null,
      isRestatementLoading: false,
      getDateFormat,
      checkAccess,
      getStatus,
      acailableDocuments: [],
      body: {
        document_id: null,
        with_date_start: false,
        item_status: null
      },
      isLoading: false,
      dependencyId: null,
      actions: {
        documents: [
          {
            id: 1,
            name: 'mdi-plus-outline',
            action: (item) => this.handleClickAvailableDocuments(item),
            checkAccess: (item) => checkAccess('backOffice') && item.status_document === 1,
            color: 'success',
            tooltip: 'tooltip.add',
            buttonOptions: { fab: true, outlined: true, xSmall: true, icon: true, color: 'success' }
          },
          {
            id: 2,
            name: 'mdi-delete-outline',
            action: (item) => this.handleClickCancelStatement(item.id),
            checkAccess: (item) => checkAccess('backOffice') && item.status_document === 1,
            color: 'error',
            tooltip: 'tooltip.cancelStatement',
            buttonOptions: { fab: true, outlined: true, xSmall: true, icon: true, color: 'error' }
          },
          {
            id: 3,
            name: ' mdi-arrow-right',
            action: (item) => this.documentRedirect(item),
            checkAccess: (item) => item?.info_for_statement.content_type,
            color: 'primary',
            tooltip: 'tooltip.go'
          }
        ],
        missingStatement: [
          {
            id: 1,
            name: 'mdi-plus-outline',
            action: (item) => this.handleClickAvailableDocuments(item),
            checkAccess: (item) => checkAccess('backOffice') && item.status_document === 1,
            color: 'green',
            tooltip: 'tooltip.add',
            buttonOptions: { xSmall: true, fab: true, icon: true, outlined: true }
          },
          {
            id: 2,
            name: 'mdi-delete-outline',
            action: (item) => this.handleClickCancelStatement(item.id),
            checkAccess: (item) => checkAccess('backOffice') && item.status_document === 1,
            color: 'error',
            tooltip: 'cancelStatement',
            buttonOptions: { xSmall: true, fab: true, icon: true, outlined: true }
          }
        ]
      },
      termReports: [
        { id: 1, label: this.$t('dateIssue'), value: true },
        { id: 2, label: this.$t('datePull'), value: false }
      ]
    }
  },
  computed: {
    ...mapGetters(['sailorIsCadet', 'getDirectoryObject', 'crewingManagerByID']),
    ...mapState({
      id: state => state.sailor.sailorId,
      nameLang: state => state.main.lang === 'en' ? 'name_eng' : 'name_ukr',
      listStatus: state => state.directory.listStatusPositionStatement
    }),
    mediaFilesArray () {
      if (!this.selectedDocument?.info_for_statement.is_international) return this.$refs.mediaContent?.filesArray || []
      else return []
    }
  },
  validations () { return addDocToPackage(this) },
  watch: {
    sailorDocument () {
      this.sortDocumentDependencies()
    }
  },
  mounted () {
    this.sortDocumentDependencies()
  },
  methods: {
    ...mapActions([
      'getPacketStatementById',
      'getSQCStatements',
      'cancelStatementInPaket',
      'getAvailableDocuments',
      'addDocumentInPackage',
      'setSailorPositionFileCertificateETI',
      'createRestatementSQC'
    ]),

    createPayment (url) { // Create payment link with platon
      const params = new URLSearchParams({ callback_url: window.location.href })
      this.$api.get(`${process.env.VUE_APP_API}${url}?${params}`, { responseType: 'text' }).then(response => {
        if (response?.code === 200) {
          document.getElementById('app').innerHTML = response.data
          document.getElementById('pay').click()
        }
      })
    },

    createDiploma () {
      this.$swal({
        title: this.$t('warning'),
        text: this.$t('createDiplomaConfirmation'),
        icon: 'info',
        buttons: [this.$t('cancel'), this.$t('confirm')],
        dangerMode: true
      }).then(confirmation => {
        if (confirmation) {
          this.$api.post(`api/v1/back_off/packet/${this.paketStatementById.id}/create_diploma_for_proof/`).then(response => {
            if ([200, 201].includes(response?.code)) {
              this.$notification.success('createdDiplomaStatement')
              this.getPacketStatementById({ ...this.$route.params })
            }
          })
        }
      })
    },

    async newRestatementSQC () {
      this.isRestatementLoading = true
      const response = await this.createRestatementSQC({ ...this.$route.params })
      if (SUCCESS_CODE.includes(response?.code)) this.getSQCStatements(this.$route.params.id)
      this.isRestatementLoading = false
    },

    openPaymentReceiptModal (item) {
      if (item.info_for_statement.is_international) {
        if (!item.photo.some(el => el.file_type === 'international_certificate')) {
          return this.$swal({
            icon: 'warning',
            title: this.$t('warning'),
            text: this.$t('notify.warning.downloadCopyEtiCertificateIS')
          })
        }
      }
      this.selectedDocument = item
      this.$refs.sailorPositionStatementReceiptUpload.modal = true
    },
    async uploadFileCertificate () {
      this.isLoading = true
      let data = {
        body: {
          photo: [...this.$refs.mediaContent.filesArray],
          file_type: 'international_certificate',
          id_document: this.selectedDocument.info_for_statement.id,
          type_document: 'StatementETI'
        }
      }
      const response = await this.setSailorPositionFileCertificateETI(data)
      if (response) {
        this.$refs.uploadFileCertificateETIModal.changeModalState(false)
        this.$refs.mediaContent.removeAllFile()
        await this.getPacketStatementById({ ...this.$route.params })
      }

      this.isLoading = false
    },

    sortDocumentDependencies () {
      if (this.paketStatementById) {
        if (this.paketStatementById.dependencies) {
          this.paketStatementById.dependencies.documents_and_statement.sort((a, b) => {
            if (a.status > b.status) return -1
          })
        }
        const validQualificationDoc = this.paketStatementById.dependencies?.documents_and_statement
          .find(value => [1, 2].includes(value.status) && value.type_document_name === 'Кваліфікаційний документ')
        if (!validQualificationDoc) {
          this.paketStatementById.dependencies.documents_and_statement.map(item => {
            item.allowCreateDiploma = item.status === 1 && item.type_document_name === 'Підтвердження кваліфікаційного документу'
            return item
          })
        }
      }
    },

    documentRedirect (item) {
      this.$router.push({
        name: setLinksByContentType(item.info_for_statement),
        params: { id: this.$route.params.id, documentID: item.info_for_statement.id }
      })
    },

    handleClickCancelStatement (documentId) {
      this.$swal({
        title: `${this.$t('youSureThatWantCancelStatement')}?`,
        icon: 'warning',
        buttons: [this.$t('no'), this.$t('yes')]
      }).then(async response => {
        if (response) {
          await this.cancelStatementInPaket({ id: this.$route.params.id, packetId: this.$route.params.documentID, documentId: documentId })
          let ArrayKey = ['missing', 'documents_and_statement', 'crewing_and_service']
          ArrayKey.forEach(key => (this.paketStatementById.dependencies[key] = this.paketStatementById.dependencies[key].filter(el => el.id !== documentId)))
          await this.getPacketStatementById({ ...this.$route.params })
        }
      })
    },
    async handleClickAvailableDocuments (item) {
      this.$refs.packageModal.changeModalState(true)
      const response = await this.getAvailableDocuments({ packetId: this.$route.params.documentID, dependencyId: item.id })
      this.dependencyId = item.id
      if (SUCCESS_CODE.includes(response.code)) this.acailableDocuments = response.data
    },
    addDocunent () {
      if (this.$v.$invalid) return this.$v.$touch()
      this.$swal({
        icon: 'warning',
        title: `${this.$t('titleModalPackage')} ?`,
        buttons: [this.$t('no'), this.$t('yes')]
      }).then(async (confirm) => {
        if (confirm) {
          this.isLoading = true
          const response = await this.addDocumentInPackage(({ packetId: this.$route.params.documentID, dependencyId: this.dependencyId, body: this.body }))
          if (SUCCESS_CODE.includes(response.code)) {
            await this.getPacketStatementById({ ...this.$route.params })

            this.isLoading = false
            this.$refs.packageModal.changeModalState(false)
            this.$notification.success('success')
            this.$v.$reset()
            this.body.document_id = null
          }
          this.isLoading = false
        }
      }).finally(() => { this.$v.$reset() })
    },
    openUploadCopyFileETIModal (item) {
      this.$refs.uploadFileCertificateETIModal.changeModalState(true)
      this.selectedDocument = item
    },
    closeModal () {
      // if (this.$refs.uploadFileCertificateETIModal) {
      //   this.$refs.uploadFileCertificateETIModal.changeModalState(false)
      //   this.$refs.mediaContent.removeAllFile()
      // } else if (this.$refs.packageModal) this.$refs.packageModal.changeModalState(false)
      this.$v.$reset()
      this.body.document_id = null
      this.body.item_status = null
      this.selectedDocument = null
    },
    closeModalPackageModal () {
      if (this.$refs.packageModal) this.$refs.packageModal.changeModalState(false)
      this.$v.$reset()
      this.body.document_id = null
      this.body.item_status = null
      this.selectedDocument = null
    }
  }
}
</script>
